import React, { useState, useEffect } from "react";
import { Row, Container, Col } from "react-bootstrap";
import { Validations, emptyValidationsSell } from "../FormValidations";
import { Input } from "../Input";
import { successForm } from "./SubmitNewProperty";

function component(
  formData,
  handleFormData,
  page,
  handleImagesChange,
  handleRemoveInput,
  handleAddInput
) {
  switch (page) {
    case 0:
      return (
        <Input
          title={<h1 className="bolder">¡Vende tu inmueble con nosotros!</h1>}
          info="Completando el siguiente formulario podrás listar tu inmueble en nuestro marketplace."
          inputLabel={
            <>
              <b className="fs-4">Antes de comenzar ¿Aceptas nuestra </b>
              <a className="underlined fs-4" href="/personal-data">
                Política de tratamiento de datos?
              </a>
            </>
          }
          inputKey="dataPolicy"
          inputValue={formData["dataPolicy"]}
          formData={formData}
          handleFormData={handleFormData}
          inputType="radio"
          radio={["Sí", "No"]}
        />
      );
    case 1:
      return (
        <Input
          inputLabel={
            <>
              <b className="fs-4">
                ¿Cómo es el nombre de la persona / empresa que aparecerá como
                agente del inmueble?
              </b>
              <br />
              <img
                src={require("../../../../base_styles/Img/ex.png")}
                alt="example"
                width="100%"
              />
            </>
          }
          inputType="text"
          inputKey="agent"
          inputValue={formData["agent"]}
          handleFormData={handleFormData}
          formData={formData}
        />
      );
    case 2:
      return (
        <Input
          inputLabel={<b className="fs-5">¿Cómo es tu email?</b>}
          inputType="mail"
          inputId="email-in"
          inputKey="email"
          inputValue={formData["email"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 3:
      return (
        <Input
          inputLabel={<b className="fs-5">¿Cuál es tu número de celular?</b>}
          inputType="text"
          inputId="phone-in"
          inputKey="phone"
          inputValue={formData["phone"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 4:
      return (
        <Input
          inputType="text"
          inputId="email-in"
          inputLabel={
            <>
              <b className="fs-4">
                Inserta el url del logo de agente que quieres que aparezca. -
                Opcional.
              </b>
            </>
          }
          inputValue={formData["logo"]}
          inputKey="logo"
          handleFormData={handleFormData}
          formData={formData}
        />
      );
    case 5:
      return (
        <Input
          inputType="select"
          inputLabel={
            <>
              <b className="fs-4">Ciudad</b>
            </>
          }
          select={[
            "Bogotá y alrededores",
            "Medellín y alrededores",
            "Cali",
            "Bucaramanga",
            "Cartagena",
            "Barranquilla",
            "Pereira",
            "Armenia",
            "Manizales",
            "Ibagué",
            "Santa Marta",
            "Cúcuta",
          ]}
          inputId="city-in"
          inputValue={formData["city"]}
          inputKey="city"
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 6:
      return (
        <Input
          inputType="text"
          inputLabel={
            <>
              <b className="fs-4">Barrio</b>
              <br />
              consulta{" "}
              <a
                target="_blank"
                rel="noreferrer"
                className="underlined fs-5"
                href="https://es.wikipedia.org/wiki/Anexo:Barrios_de_Bogot%C3%A1"
              >
                acá{" "}
              </a>
              la guía de barrios y unidad de planeamiento zonal (UPZ) en Bogotá.
            </>
          }
          inputValue={formData["neighborhood"]}
          inputKey="neighborhood"
          formData={formData}
          handleFormData={handleFormData}
        />
      );

    case 7:
      return (
        <Input
          inputKey="address"
          inputType="text"
          inputLabel={<b className="fs-4">Dirección</b>}
          inputValue={formData["address"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 8:
      return (
        <Input
          inputType="select"
          inputKey="type"
          inputLabel={<b className="fs-4">Tipo de inmueble</b>}
          select={["Casa", "Apartamento"]}
          inputValue={formData["type"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 9:
      return (
        <Input
          inputLabel={<b className="fs-4">Valor del inmueble</b>}
          inputType="money"
          inputKey="cost"
          inputValue={formData["cost"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 10:
      return (
        <Input
          inputLabel={<b className="fs-4">Valor de la administración</b>}
          inputType="money"
          inputKey="administration"
          inputValue={formData["administration"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 11:
      return (
        <Input
          inputLabel={<b className="fs-4">Estrato</b>}
          inputType="select"
          inputKey="economic_class"
          select={["1", "2", "3", "4", "5", "6"]}
          inputValue={formData["economic_class"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 12:
      return (
        <Input
          inputLabel={<b className="fs-4">En qué piso está ubicado?</b>}
          inputType="text"
          inputKey="floor"
          inputValue={formData["floor"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 13:
      return (
        <Input
          inputLabel={
            <b className="fs-4">Número de parqueaderos disponibles</b>
          }
          inputKey="parkings"
          inputType="text"
          inputValue={formData["parkings"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 14:
      return (
        <Input
          inputLabel={<b className="fs-4">Año de construcción</b>}
          inputType="text"
          inputKey="construction_date"
          inputValue={formData["construction_date"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 15:
      return (
        <Input
          inputLabel={<b className="fs-4">Número de habitaciones</b>}
          inputType="text"
          inputKey="rooms"
          inputValue={formData["rooms"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 16:
      return (
        <Input
          inputLabel={<b className="fs-4">Número de baños</b>}
          inputType="text"
          inputKey="bathrooms"
          inputValue={formData["bathrooms"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 17:
      return (
        <Input
          inputLabel={
            <>
              <b className="fs-4">
                Área construida (m<sup>2</sup>)
              </b>
            </>
          }
          inputType="text"
          inputKey="private_area"
          inputValue={formData["private_area"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 18:
      return (
        <Input
          inputLabel={
            <>
              <b className="fs-4">
                Área habitable (m<sup>2</sup>)
              </b>
              <br />
              Área construida menos áreas de terraza. (en caso que no haya
              terraza, es igual que área construida).
            </>
          }
          inputType="text"
          inputKey="living_area"
          inputValue={formData["living_area"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 19:
      return (
        <Input
          inputLabel={
            <>
              <b className="fs-4">Breve descripción del inmueble</b>
              <br />
              Ejemplo: "Espectacular apartamento para estrenar. El edificio
              tiene en el primer piso un área de multitrabajo (coworking) y
              control de acceso automatizado, servicio de conserjerí­a de lunes
              a sábado".
            </>
          }
          inputType="textarea"
          inputKey="description"
          inputValue={formData["descripcion"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 20:
      return (
        <Input
          inputType="multiCheckbox"
          checkbox={[
            "Ascensor",
            "Seguridad",
            "Depósito",
            "Gimnasio",
            "Terraza",
            "Salón comunal",
            "Parqueadero visitantes",
            "Zonas verdes",
            "Lavandería",
            "Parque infantil",
            "Piscina",
            "Sauna",
            "cancha de squash",
          ]}
          inputLabel={<b className="fs-4">Amenities</b>}
          inputValue={formData["amenities"]}
          inputKey="amenities"
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 21:
      return (
        <Input
          inputKey="photos"
          inputLabel={<b className="fs-4">Fotos</b>}
          inputType="photos"
          inputValue={formData["photos"]}
          formData={formData}
          handleFormData={handleFormData}
          handleImagesChange={handleImagesChange}
          handleRemoveInput={handleRemoveInput}
          handleAddInput={handleAddInput}
        />
      );
    case 22:
      return (
        <Input
          title={
            <h1 className="bolder">
              Enviando datos
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </h1>
          }
        />
      );
    default:
      return <></>;
  }
}

export function MultipageFormSell() {
  let successform = new successForm();
  const [page, setPage] = useState(0);
  const [validate, setValidate] = useState(false);
  const [emptyValidation, setEmptyValidation] = useState(false);
  const [inputErrorStatus, setInputErrorStatus] = useState(false);

  const handleImagesChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...formData["photos"]];
    list[index][name] = value;
    setFormData({ ...formData, photos: list });
  };

  const handleRemoveInput = (index) => {
    const list = [...formData["photos"]];
    list.splice(index, 1);
    setFormData({ ...formData, photos: list });
  };

  const handleAddInput = () => {
    setFormData({
      ...formData,
      photos: [...formData["photos"], { image_url: "" }],
    });
  };

  function handleSubmit() {
    setValidate(true);
  }
  function handleBackPage() {
    setPage(page - 1);
  }

  const [formData, setFormData] = useState({
    dataPolicy: "",
    agent: "",
    phone: "",
    email: "",
    logo: "",
    city: "",
    upz: "",
    address: "",
    type: "",
    cost: "",
    administration: "",
    economic_class: "",
    floor: "",
    parkings: "",
    construction_date: "",
    rooms: "",
    bathrooms: "",
    private_area: "",
    living_area: "",
    description: "",
    amenities: [],
    photos: [{ image_url: "" }],
    lastModified: "",
    images_number: 0,
  });
  const errorMessages = {
    emptyValue: "Campo requerido.",
    dataPolicy: "Debes aceptar nuestra política de datos para continuar.",
    city: "Selecciona una ciudad válida",
    neighborhood: "Selecciona una opción válida para continuar.",
    address: "Este campo es requerido",
    type: "Selecciona una opción válida para continuar.",
    cost: "El campo es requerido, Verifica los datos ingresados.",
    administration: "El campo es requerido, Verifica los datos ingresados.",
    economic_class: "Selecciona una opción válida para continuar.",
    floor: "El campo es requerido, Verifica los datos ingresados.",
    parkings: "El campo es requerido, Verifica los datos ingresados.",
    construction_date: "El campo es requerido, Verifica los datos ingresados.",
    rooms: "El campo es requerido, Verifica los datos ingresados.",
    bathrooms: "El campo es requerido, Verifica los datos ingresados.",
    private_area: "El campo es requerido, Verifica los datos ingresados.",
    living_area: "El campo es requerido, Verifica los datos ingresados.",
    description: "El campo es requerido, Verifica los datos ingresados.",
    photos: "",
  };
  const handleFormData = (k, v) => {
    if ((k !== "amenities") & (k !== "photos")) {
      setFormData({
        ...formData,
        [k]: v,
        lastModified: k,
      });
    } else if (k === "amenities") {
      const isChecked = v.target.checked;
      if (isChecked) {
        setFormData({
          ...formData,
          [k]: [...formData[k], v.target.value],
        });
      } else {
        let index = formData[k].indexOf(v.target.value);
        formData[k].splice(index, 1);
        setFormData({ ...formData, [k]: formData[k] });
      }
    } else if (k === "photos") {
      setFormData({
        ...formData,
        lastModified: k,
        images_number: formData["photos"].length,
      });
    }
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        document.getElementById("NextBtn").click();
      }
    };
    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);
  useEffect(() => {
    if (validate) {
      let results = Validations(
        formData[formData["lastModified"]],
        formData["lastModified"]
      );
      // if (!results[1]) {
      //   setInputErrorStatus(true);
      //   setValidate(false);
      //   setEmptyValidation(false);
      //   return;
      // } else {
      let emptyResult = emptyValidationsSell(page, formData);
      setEmptyValidation(emptyResult);
      setValidate(false);
      setInputErrorStatus(false);
      if (emptyResult) return;
      // }
      setPage((page) => page + 1);
      setTimeout(() => {
        if (page === 21) {
          console.log(formData);
          successform.main(formData);
        }
      }, 2000);
    }
  }, [validate]);
  return (
    <Container fluid className="mform-container">
      <Row className="header sellpage"></Row>
      <Row className="justify-content-center content">
        <Col md={6} xs={10}>
          <div className="logo mb-4"></div>
          {page >= 0 && (
            <button
              className="btn-transparent-grey mb-4"
              onClick={handleBackPage}
              disabled={page === 0 || page === 22}
            >
              ← Atrás
            </button>
          )}
          <div className="component">
            {component(
              formData,
              handleFormData,
              page,
              handleImagesChange,
              handleRemoveInput,
              handleAddInput
            )}
          </div>
          <div className="error-msg">
            {inputErrorStatus && (
              <p>{errorMessages[formData["lastModified"]]}</p>
            )}
            {emptyValidation && <p>{errorMessages["emptyValue"]}</p>}
          </div>
          <button
            className="btn-rounded-green mt-3"
            id="NextBtn"
            onClick={handleSubmit}
            disabled={page === 22}
          >
            {page >= 0 && page <= 21 ? "Siguiente" : "Enviar"}→
          </button>
        </Col>
      </Row>
    </Container>
  );
}
